<template>
  <div>
    <aad-title>Notes</aad-title>

    <div v-if="allNotes && allNotes.length > 0" class="print-hide">
      <a href="#" @click="printNotes" class="btn btn-primary-solid my-2 mr-2"
        >Print Notes</a
      >
      <a href="#" @click="emailNotes" class="btn btn-primary-solid my-2"
        >Export via Email</a
      >
      <transition name="fade">
        <p class="d-block my-2 font-italic" v-if="emailExporting">
          Opening export in your default email application!
        </p>
      </transition>
      <hr />
    </div>
    <div v-if="sessionNotes && sessionNotes.length > 0">
      <h2>Session Notes:</h2>
      <div v-for="note in sessionNotes" :key="note.id">
        <div class="meeting-app-info-block w-100">
          <div class="meeting-app-info-header row">
            <router-link
              :to="{
                name: 'SessionDetails',
                params: { id: note.LinkId },
              }"
              class="text-link-light-bg"
            >
              <h4>{{ note.Title }}</h4>
            </router-link>
          </div>

          <div class="meeting-app-info-body note-text">
            {{ note.NoteBody }}
          </div>
        </div>
      </div>
    </div>
    <hr
      class="my-4"
      v-if="
        speakerNotes &&
        sessionNotes &&
        sessionNotes.length > 0 &&
        speakerNotes.length > 0
      "
    />
    <div v-if="speakerNotes && speakerNotes.length > 0">
      <h2>Speaker Notes:</h2>
      <div v-for="note in speakerNotes" :key="note.id">
        <div class="meeting-app-info-block w-100">
          <div class="meeting-app-info-header row">
            <router-link
              :to="{
                name: 'SpeakerDetails',
                params: { id: note.LinkId },
              }"
              class="text-link-light-bg"
            >
              <h4>{{ note.Title }}</h4>
            </router-link>
          </div>

          <div class="meeting-app-info-body">
            {{ note.NoteBody }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      emailExporting: false,
    };
  },
  methods: {
    printNotes: function () {
      window.print();
    },
    emailNotes: function () {
      window.open(
        `mailto:${this.loggedInUser.email}?subject=American Academy of Dermatology - ${this.getMeetingName} Notes&body=${this.emailString}`
      );

      // Trigger Email Message
      this.emailExporting = true;
      setTimeout(
        function () {
          this.emailExporting = false;
        }.bind(this),
        10000
      );
    },
    notesToString(notes) {
      let output = "";
      if (notes && notes.length > 0) {
        for (let i = 0; i < notes.length; i++) {
          output += notes[i].Title + ": %0D%0A";
          output += notes[i].NoteBody + "%0D%0A";
          output += "--------------------------- %0D%0A%0D%0A";
        }
      } else {
        output += "None. %0D%0A";
        output += "--------------------------- %0D%0A%0D%0A";
      }
      return output;
    },
  },
  computed: {
    ...mapGetters(["allNotes", "loggedInUser", "getMeetingName"]),
    sessionNotes: function () {
      return this.allNotes.filter(
        (note) => note.NoteType == "session" && note.IsActive
      );
    },
    speakerNotes: function () {
      return this.allNotes.filter(
        (note) => note.NoteType == "speaker" && note.IsActive
      );
    },
    emailString: function () {
      let output = "";

      output += "Session Notes: %0D%0A%0D%0A";

      output += this.notesToString(this.sessionNotes);

      output += "%0D%0A%0D%0ASpeaker Notes: %0D%0A%0D%0A";

      output += this.notesToString(this.speakerNotes);

      return output;
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.note-text {
  white-space: pre;
}
</style>
